@media only screen and (min-height:850px) and (max-height:870px){
    .project div video{
        margin-left: 23%;
    }

}
.vidbo{
    width: 50vw;
    max-width:1000px;
    border-radius:20px;
    margin-top:-60%;
    margin-left: 3rem;

}


@media only screen and (max-width: 700px){
.center .slick-center .project {
    margin-top: 0vh !important;
    margin-left: 33vw !important;
}
.slick-dots{
    position: relative !important;
    top: -12rem !important;

}

.backimg {
    width: 15px !important;
   
}
.nextimg {
    width: 15px !important;
   
}
.vidbo {
    width: 42vw;
    max-width: 600px;
    border-radius: 20px;
    margin-top: -80%;
    position: relative;
    left: 0vw;
}
.nextimg {
    right: -2rem !important;
    position: relative;
}

.slick-dots {
    width: 90% !important;
}
.center .slick-center .project {
    margin-top: 0vh !important;
    margin-left: 29vw !important;
}

.back {
    background: rgba(255,255,255,0) !important;
    top: 42% !important;
    left: 0rem !important;
}
.next {
    top: 42% !important;
    right: 0rem !important;
    background: rgba(255,255,255,0) !important;
}

.slick-dots li button:before {
    font-size: 11px !important;
    opacity: .25;
    color: #D9D9D9 !important;
}
}