@import url("https://fonts.googleapis.com/css2?family=Abel&display=swap");

.navbarUl {
  list-style: none;
  display: flex;
  gap: 72px;
  overflow: hidden;
}

.navbarBody {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 95px 0 95px;
  height: 60px;
  /* background:linear-gradient(107.29deg, #050A30 15.64%, #4699AB 180.36%); */
}

.img,
.imgContainer {
  /* width:320px; */
  height: 48px;
  margin-top:-1.3px;
}

.imgContainer {
  padding: 10px 10px;
}
.navbarUl li a {
  color: white !important;
  text-decoration: none;
  font-family: "Abel";
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 41px;
}

.mobnavbar {
  display: none;
}

.dropdown {
  float: left;
  overflow: hidden;
}

.dropdown .dropbtn {
  color: white !important;
  background-color: transparent;
  border: none;
  text-decoration: none;
  font-family: "Abel";
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 41px;
}

.dropdown-content {
  display: none;
  position: absolute;
  background: rgba(26, 36, 74, 0.8);
  border-radius: 6.64859px;
  min-width: 110px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.dropdown-content a {
  float: none;
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  text-align: left;
}

.dropdown:hover .dropdown-content {
  display: block;
}

@media only screen and (max-width: 1150px) {
  .navbarUl {
    gap: 52px;
  }
}

@media only screen and (max-width: 1005px) {
  a {
    font-size: 18px;
  }
  .img,
  .imgContainer {
    width: 250px;
    height: 53px;
  }
}

@media only screen and (max-width: 875px) {
  .navbarUl {
    gap: 39px;
  }
  .img,
  .imgContainer {
    width: 214px;
    height: 46px;
  }
}

@media only screen and (max-width: 790px) {
  .img,
  .imgContainer {
    width: 168px;
    height: 37px;
  }
  .navbarUl {
    gap: 25px;
  }
}

@media only screen and (max-width: 1000px) {
  .navbarBody {
    display: none;
  }
  .mobnavbar {
    display: flex;
  }
}
