@font-face{
  font-family: 'Lab Sans Pro Thin';
  src: local('Lab Sans Pro Thin'), url('../../lab-sans-pro-cufonfonts-webfont/Lab\ Sans\ Pro-Thin.woff') format('woff');
}
@import url(//db.onlinewebfonts.com/c/f5f6ff7f3ec1bc2c964b9a8d5122840b?family=Lab+Sans+Pro+Light);


.scroll-container1 {
    width: 100%;
    overflow: hidden;
  }
  
  .scroll-content {
    display: flex;
    animation: scroll 25s linear  infinite ;
    gap:80px;
    align-items: center;
  }
  
  .scroll-content img {
    width: 100%;
    height: 14vh;
  }
  .scroll-content a img {
    width: 16vw;
    height: 14vh;
  }
  
  @keyframes scroll {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(-100%);
    }
  }

  .mediacoverage{
    font-family: 'Lab Sans Pro Light',sans-serif;
    font-weight: 400;
    font-style: normal;
/* font-weight: 250; */
font-size: 60px;
line-height: 112px;
text-align: center;

color: #FFFFFF;
  }

  @media only screen and (max-width:600px){
    .mediacoverage{
      font-size: 30px;
line-height: 34px;
font-weight:900;

    }

    .scroll-content{
      animation: scroll 50s linear infinite;

    }
    .scroll-container1 {
      width: 1160%;
      overflow: hidden;
  }
  
  
  }
  @media only screen and (max-width:800px){
    

    .scroll-content{
      animation: scroll 50s linear infinite;
       margin-top: 10vh;
    }

    .scroll-content img {
      width: 100%;
      height: 8vh;
    }
    .scroll-content a img {
      width: 13rem;
      height: 8vh;
  }
  .scroll-content {
    gap:80px;
  }
  }