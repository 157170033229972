@import url('https://fonts.googleapis.com/css2?family=Alumni+Sans&display=swap');
@import url(//db.onlinewebfonts.com/c/f5f6ff7f3ec1bc2c964b9a8d5122840b?family=Lab+Sans+Pro+Light);

.video {
  width: 100vw;
  min-height: 100vh;
  height: auto;
}

.videoBox {
  height: 200vh;
  width: 100vw;
}

.slick-dots li button:before {
  font-size: 11px !important;
  opacity: .25;
  color: #D9D9D9 !important;
 
}
.slick-dots{
  position: unset !important;
}
.slick-dots li.slick-active button:before {
  opacity: 1 !important;
  color: white !important;
}

.App {
  font-family: sans-serif;
  text-align: center;
}

.wrapper {
  height: 500vh;
}

.triangle-container {
  position: relative;
  height: 300px;
}

.triangle {
  position: absolute;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 150px 260px 150px;
  border-color: transparent transparent #007bff transparent;
}

.edge {
  position: absolute;
  height: 80px;
  width: 200px;
  background-color: #f8f9fa;
  border: 1px solid #007bff;
}

.edge-1 {
  transform: rotate(120deg);
  transform-origin: 0% 0%;
  top: 30%;
  left: 20%;
}

.edge-2 {
  transform: rotate(0deg);
  transform-origin: 50% 0%;
  top: 30%;
  left: 40%;
}

.edge-3 {
  transform: rotate(-120deg);
  transform-origin: 100% 0%;
  top: 30%;
  left: 60%;
}

.content {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.slider-container {
  width: 100%;
  margin: 0 auto;
  padding: 20px 0;
}

.slider-container .slick-slider {
  position: relative;
  box-sizing: border-box;
}

.slider-container .slick-slider .slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0;
}

.slider-container .slick-slider .slick-list:focus {
  outline: none;
}

.slider-container .slick-slider .slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slider-container .slick-slider .slick-track,
.slider-container .slick-slider .slick-list {
  transform: translate3d(0, 0, 0) !important;
}


.slider-container .slick-slider .slick-track {
  position: relative;
  top: 0;
  left: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.slider-container .slick-slider .slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none;
}

.slider-container .slick-slider .slick-slide img {
  width: 100%;
}

.slider-container .slick-slider .slick-prev,
.slider-container .slick-slider .slick-next {
  position: absolute;
  display: block;
  font-size: 0;
  line-height: 0;
  top: 50%;
  transform: translate(0, -50%);
  cursor: pointer;
  padding: 0;
  border: none;
  background: none;
  outline: none;
}

.slider-container .slick-slider .slick-prev:before,
.slider-container .slick-slider .slick-next:before {
  content: "";
  font-size: 30px;
  line-height: 1;
  opacity: 0.75;
  color: #333;
  transition: opacity 0.25s;
}

.slider-container .slick-slider .slick-prev:hover:before,
.slider-container .slick-slider .slick-next:hover:before {
  opacity: 1;
}

.slider-container .slick-slider .slick-prev {
  display: block;
  left: 514px !important;
  z-index: 100;
  top: 481px;
}

.slick-prev {
  left: 514px !important;
  z-index: 100;
  top: 481px;
}

.slider-container .slick-slider .slick-next {
  display: block;
  right: 550px !important;
  top: 481px;
}

.slick-next {
  right: 550px !important;
  top: 481px;
}

.slick-track {
  width: 5172px;
  opacity: 1;
  margin-top: 39px;
  transform: translate3d(-2562px, 39px, 0);
  height: 74vh !important;
}

.slick-list {
  width: 100vw;
}

.slick-track:nth-child() {
  visibility: hidden;
}

.fzbapH {
  height: 20rem !important;
}

.slick-slider .slick-list {
  transform: translate3d(-151px, 0, 0) !important;
}

.fzUeVN {
  width: 100% !important;
}

.slick-list{
  padding:0 60px !important;
}

.fzbapH {
  height: 55vh vh !important;
  width: 38vw;
  max-width: 600px;
  background-color: none;
}

.fzbapH img{
  width:100%;
  height:100%;
  object-fit: cover;
  transition: transform 400ms ease-in-out;
}

.ddd1{
  background:url("/public/use.webp") !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  background-position: center center !important;
  /* object-fit: cover; */
  border-radius: 45px;
}
.ddd2{
  background:url("/public/geo.webp") !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  background-position: center center !important;
  /* object-fit: cover; */
  border-radius: 45px;


}
.ddd3{
  background:url("/public/vehicle.webp") !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  background-position: center center !important;
  /* object-fit: cover; */
  border-radius: 45px;


}


.iZSzPQ{
  padding:0 !important;
}

.dRemdl button {
top:80% !important;
right:24rem !important;
background: rgba(255,255,255,0);
}
.next{
  top:40% !important;
right:0rem !important;
background: rgba(255,255,255,0) !important;
}
.back{
  background: rgba(255,255,255,0) !important;
  top:40% !important;
  left:0rem !important;
}
@media only screen and (max-width:1830px) and (min-width:1500px){
  .center .slick-center .project {
    margin-left: 55vh !important;
}
}
@media only screen and (max-height:850px) and (min-width:800px){
  .center .slick-center .project {
    margin-left: 55vh !important;
  }
}
.center .slick-center .project {
  border-radius: 37px;
  margin-left:55vh !important;
  transform:scale(1) !important ;
}
@media only screen and (min-width:1800px){
  .center .slick-center .project {
    border-radius: 37px;
    margin-left: 42vh !important;
  }
}
@media (max-width: 768px) {
  .slider-container {
    width: 90%;
  }

  .slider-container .slick-slider .slick-prev {
    left: 0;
  }

  .slider-container .slick-slider .slick-next {
    right: 0;
  }
}

.imgSize {
  height: 450px;
  width: 450px;
}

.bodyContainer {
  height: 100vh;
}

/* .slick-active:not(.slick-current){
  transform:scale(1.5);
} */
.project span{
  font-family: 'lato', sans-serif;
  font-style: normal;
font-weight: 400;
font-size: 36px;
text-align: center;
color: #FFFFFF;
position: relative;
top:350px;
display: none;

}
.project div{
  font-family: 'lato', sans-serif;
font-style: normal;
font-weight: 300;
    font-size: 20px;
text-align: center;
color: #FFFFFF;
position: relative;
top:365px;
display: none;
}

.slick-center .project span{
  display: inline-block;
}
.slick-center .project div{
  display: block;
}


@media only screen and (max-width:1290px){
  .back{
    left:0rem !important;
  }
  .next{
    right:0rem !important;
  }
  .slick-track {
    height: 84vh !important;
}
.center .slick-center .project {
  border-radius: 37px;
  margin-left: 34vw !important;
}
.project div {
  font-size: 16px;
}

}

@media only screen and (max-width:990px){
  .center .slick-center .project{
    transform: scale(1.3) !important;
  }
}

@media only screen and (max-width: 840px){
.iZSzPQ {
    width: 80% !important;
}

.slick-slider{
  transform: translate3d(27px,0,0) !important;
}
.fzbapH{
  width: 33vw !important;
  height:15rem !important;
}
.center .slick-center .project{
  margin-top: 12vh;
      margin-left:35vw !important;
}

.project span {
  top: 265px;
  font-size: 30px !important;
}
.project div {
  top: 275px;
}


}
@media only screen and (max-width:642px){
.project span {
  font-size: 24px !important;
}
.project div {
  font-size: 14px !important;
}
}
@media only screen and (max-width:570px) {
  .backimg{
    width:30px !important;
  }
  .nextimg{
    width:30px !important;
  }

  .fzbapH{
    width: 36vw !important;
    height: 8rem !important;
  }
  .center .slick-center .project {
    margin-top: 12vh;
    margin-left: 26vw !important;
    width: 45vw !important;
}
.project span {
  font-size: 22px !important;
}
.project div {
  font-size: 12px !important;
}
.project span {
  top: 213px;
}
.project div {
  top: 220px;
}
}

@media only screen and (max-width:450px){
  .slick-dots {
    width: 80%;
    margin-top:-70% !important;
}
.nextimg {
  right: -2rem;
  position: relative;
}
.backimg {
  width: 30px !important;
  left: -2rem;
  position: relative;
}
.project span {
  top:150px;
}
.project div {
  top: 160px;
}

}


@media only screen and (max-width:350px){
  .slick-dots {
    width: 80%;
    margin-top: -27% !important;
}
}


@media only screen and (min-width: 2200px){
.center .slick-center .project {
    border-radius: 37px;
    margin-left: 51vh !important;
}
}