.overlayabout {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .info-boxabout {
    position: relative;
    background: white;
    border-radius: 15px;
    padding: 20px;
    z-index:9999;
    width: 40vw;
    height: 85vh;
    
  }
  
  .info-content{
    
    padding: 0 26px 0 26px;
    display: flex;
    /* align-items: center; */
    gap: 42px;
    height: 80%;
font-size: 21px;
  }

  @media only screen and (max-width:1050px){
    .info-content{
      font-size: 18px;
    }
  }
  @media only screen and (max-width:810px){
    .info-content{
      font-size: 15px;
    }
  }
  @media only screen and (max-width:700px){
    .info-boxabout {
      width: 65vw;
  }
  }
  @media only screen and (max-width:450px){
    .info-content {
      font-size: 13px;
  }
  }

 