@import url(//db.onlinewebfonts.com/c/f5f6ff7f3ec1bc2c964b9a8d5122840b?family=Lab+Sans+Pro+Light);

@import url("https://fonts.googleapis.com/css2?family=Alumni+Sans:wght@100;200;300;400;500&display=swap");
@font-face {
  font-family: "Lab Sans Pro Thin";
  font-weight: bold;
  src: local("Lab Sans Pro Thin"),
    url("../../lab-sans-pro-cufonfonts-webfont/Lab\ Sans\ Pro-Thin.woff")
      format("woff");
}
.Ztechmain {
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
}

.image {
  width: 70%;
}

.Ztechmain1 {
  display: flex;
  justify-content: center;
  align-items: center;
}

.scaleup {
  animation: scal 3.5s ease 1 !important;
}

@keyframes scal {
  0% {
    transform: scale(0.2);
  }
  100% {
    transform: scale(1);
  }
}
.translated-rotated1 {
  transform: translateX(-20%);
  transition: transform 2s ease !important;
}

.Ztechmain2sub {
  display: none;
  background: rgba(217, 217, 217, 0.22);
  border-radius: 30px;
  width: 672px;
  height: 389px;
  /* display: flex; */
  align-items: center;
  padding-left: 4rem;
  animation: disp 3s ease-in-out;
}

.Ztechmain2sub span {
  font-family: "Lab Sans Pro Thin";
  font-weight: bolder;
  font-style: normal;
  font-size: 64px;
  line-height: 72px;
  color: #ffffff;
}

.image {
  transition: transform 5s;
}

.animated {
  transform: translateX(60%) scale(0) translateX(-60%) scale(1.1);
}

.Ztechsecond {
  width: 100vw;
  max-width: 1920px;
  min-height: 100vh;
  display: flex;
  justify-content: center;
}

.Ztechsecond nav {
  display: flex;
}

.nav2 {
  width: 230px;
  height: 250px;
  padding-top: 56px;
  padding-left: 89px;
  position: absolute;
  display: flex;
}

.active {
  color: yellow;
  font-weight: bold;
}

.Ztechsecond nav {
  position: relative;
}

.Ztechsecond nav ul {
  margin: 0;
  padding: 0;
  display: flex;
  gap:5%;
  flex-direction: column;
}

.Ztechsecond nav ul li {
  display: inline-block;
  margin-right: 20px;
}

.Ztechsecond nav ul li a {
  text-decoration: none;
  color: white;
  text-decoration: none;
  font-family: "Abel";
  font-style: normal;
  font-size: 28px;
  line-height: 51px;
}

.Ztechsecond nav ul li.active a {
  font-weight: bolder;
  font-size: 55px;
  color:rgb(49, 195, 183);
}

.Ztechsecond .fixed {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
}

.Ztechfadeup {
  min-height: 110vh;
  height: auto;
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  background: linear-gradient(107.29deg, #ffffff 15.64%, #4699ab 180.36%);
}

.Ztechfadeupfirst {
  font-family: 'Montserrat';
  font-style: normal;
  letter-spacing: -0.03em;
  font-weight: 400;
  font-size: 101.767px;
  line-height: 114px;
  text-align: center;
  color: #0e1d41;
}

.ZtechfadeupSecond {
  font-family: "Lab Sans Pro Light", sans-serif;
  font-style: normal;
  font-weight: 250;
  font-size: 30.5301px;
  line-height: 34px;
  text-align: center;
  color: #0e1d41;
  padding: 0 8%;
}
.ZtechfadeupThird {
  width: 100vw;
}
.ZtechfadeupImage {
  width: 100%;
  height: 35vw;
}

.zTechLast {
  height: auto;
  min-height: 120vh;
  width: 100vw;
  max-width: 1920px;
  padding-top: 3%;
  display: flex;
  gap: 30px;
  flex-direction: column;
  justify-content: space-between;
}

.zTechLastText {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: column;
  height: 30vh;
}

.zTechimg {
  height: 83vh;
  width: 90vw;
  max-width: 1820px;
  border-radius: 75px;
}

.zTechLastImages {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.plus1 {
  position: absolute;
  top: 27%;
  left: 26%;
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.plus2 {
  position: absolute;
 
  top: 47%;
  left: 26%;

  background-color: transparent;
  border: none;
  cursor: pointer;
}

.plus3 {
  position: absolute;
  top: 68%;
    left: 26%;
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.plus4 {
  position: absolute;
  top: 27%;
    left: 72%;
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.plus5 {
  position: absolute;
  top: 47%;
    left: 37%;
  background-color: transparent;
  border: none;
  cursor: pointer;
}
.plus6 {
  position: absolute;
  top: 47%;
  left: 48.5%;
  background-color: transparent;
  border: none;
  cursor: pointer;
}
.plus7 {
  position: absolute;
  top: 47%;
  left: 72%;
  background-color: transparent;
  border: none;
  cursor: pointer;
}
.plus8 {
  position: absolute;
  top: 68%;
  left: 72%;
  background-color: transparent;
  border: none;
  cursor: pointer;
}

#home {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-left: 20%;
}
#about {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-left: 20%;
}
#services {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-left: 20%;
}
#portfolio {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-left: 20%;
}
#contact {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-left: 20%;
}
#home img {
  width: 30vw;
  height: auto;
  box-shadow: 0px 4px 24px 0px rgba(255, 255, 255, 0.25);
    border-radius: 22px;
}
#about img {
  width: 30vw;
  height: auto;
  box-shadow: 0px 4px 24px 0px rgba(255, 255, 255, 0.25);
    border-radius: 22px;
}
#services img {
  width: 30vw;
  height: auto;
  box-shadow: 0px 4px 24px 0px rgba(255, 255, 255, 0.25);
    border-radius: 22px;
}
#portfolio img {
  width: 30vw;
  height: auto;
  box-shadow: 0px 4px 24px 0px rgba(255, 255, 255, 0.25);
    border-radius: 22px;
}
#contact img {
  width: 30vw;
  height: auto;
  box-shadow: 0px 4px 24px 0px rgba(255, 255, 255, 0.25);
    border-radius: 22px;
}


.ztechp {
  font-style: normal;
  font-family: "lato";
  font-weight: 300;
  font-size: 1.2vw;
  line-height: 2vw;
  text-align: justify;
  color: #ffffff;
  padding: 0 24% 6% 24%;
  margin-top:-1%;
}

.zTechLastText1 {
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size:80px;
  line-height: 114px;
  text-align: center;
  color: #ffffff;
}

.zTechLastText2 {
  font-style: normal;
  font-family: "lato";
  font-weight: 300;
  padding: 0px 15%;
  font-size: 18px;
  line-height: 30px;
  text-align: justify;

  color: #ffffff;
}
.boox1 {
  position: absolute;
  top: 35%;
    width: 44%;
    left: 26%;
}
.boox2 {
  position: absolute;
  top: 39%;
  width: 44%;
  left: 26%;
}
.boox3 {
  position: absolute;
  top: 26%;
    width: 44%;
    left: 25%;
}
.boox4 {
  position: absolute;
  top: 26%;
  width: 44%;
  left: 46%;
}
.boox5 {
  position: absolute;
  top: 43%;
    width: 44%;
    left: 37%;
}
.boox6 {
  position: absolute;
  top: 41%;
    width: 44%;
    left: 44%;
}
.boox7 {
  position: absolute;
  top: 33%;
    width: 44%;
    left: 46%;
}
.boox8 {
  position: absolute;
  top: 40%;
    width: 44%;
    left: 46%;
}

@media only screen and (max-width: 1150px) {
  .zTechimg {
    height: 72vh;
    width: 100vw;
  }
  .zTechLast {
    min-height: 110vh;
  }

}
@media only screen and (max-width: 1000px) {
  .ZtechfadeupSecond {
    font-size: 22px;
    line-height: 28px;
  }
  .Ztechfadeupfirst {
    font-size: 80px;
  }
  .zTechLast {
    min-height: 100vh;
  }
  .zTechimg {
    height: 64vh;
    width: 100vw;
  }

  .zTechLastText2 {
    font-size: 21px;
    line-height: 26px;
  }
  
}
@media only screen and (max-width: 850px) {

  .zTechLastText1 {
    font-size: 74.767px;
  }
  .ZtechfadeupSecond {
    font-size: 22px;
    line-height: 28px;
  }
  .Ztechfadeupfirst {
    font-size: 9vw;
  }
  .zTechLastText2 {
    font-size: 18px;
    line-height: 21px;
  }
  .zTechLast {
    min-height: 100vh;
  }
  .zTechimg {
    height: 64vh;
    width: 100vw;
  }

  .zTechimg {
    height: 55vh;
  }
  .Ztechsecond .fixed {
    position: fixed;
    top: 0px;
    left: 0px;
    z-index: 999;
}
.nav2 {
  width: 230px;
  height: 250px;
  padding-top: 16px;
  padding-left: 37px;
  position: absolute;
}

}

@media only screen and (min-width: 1800px) {
  .zTechLast {
    min-height: 90vh;
  }
  .zTechimg {
    height: 70vh;
  }
}

@media only screen and (max-width:700px){
  .Ztechfadeup {
    min-height: 100vh;
    height: auto;
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
    background: linear-gradient(107.29deg, #ffffff 15.64%, #4699ab 180.36%);
    flex-direction: column-reverse;
    gap: 30px;
}
.ZtechfadeupImage {
  width: 100%;
  height: 50vw;
}

.zTechLastText1 {
 
  font-size: 11vw;
  line-height: 114px;
  text-align: center;
  color: #ffffff;
}
.zTechimg {
  height:auto;
}
.zTechLast {
  min-height: auto;
}
.zTechLast {
  gap: 15vh;
}

.plus1 img{
  width:56%;
}
.plus2 img{
  width:56%;
}
.plus3 img{
  width:56%;
}
.plus4 img{
  width:56%;
}
.plus5 img{
  width:56%;
}
.plus6 img{
  width:56%;
}
.plus7 img{
  width:56%;
}
.plus8 img{
  width:56%;
}

.plus1{
  left:22%;
}
.plus2{
  left:22%;
}
.plus3{
  left:22%;
}

.plus5{
  left:34%;
}
.plus6{
  left:47%;
}
.zTechimg {
 
  border-radius: 31px;
}
}

@media only screen and (max-width:400px){
  .plus1{
    left:20%;
  }
  .plus2{
    left:20%;
  }
  .plus3{
    left:20%;
  }
  
  .plus5{
    left:32%;
  }
  .plus6{
    left:45%;
  }
  .plus4{
    left:69%;
  }
  
  .plus7{
    left:69%;
  }
  .plus8{
    left:69%;
  }


}

@media only screen and (min-width:2200px){
  #home img {
    width: 40vw;
    height: 23vw;
  }
  #about img {
    width: 40vw;
    height: 23vw;
  }
  #services img {
    width: 40vw;
    height: 23vw;
  }
  #portfolio img {
    width: 40vw;
    height: 23vw;
  }
  #contact img {
    width: 40vw;
    height: 23vw;
  }
  .ztechp {
    font-size: 1.2vw;
    line-height: 1.6vw;
    padding: 0 17% 8% 16%;
}
.Ztechsecond .fixed {
  left: 14.7%;
}
  
}