body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.slick-list, .slick-slider, .slick-track{
  padding: 1.5rem 0;
}

.center .slick-center .project{
  transform: scale(1.3);
  z-index: 10000;
  transition: all 400ms ease-in-out;
  pointer-events: all;
  opacity: 1;
}

.center .project{
  opacity: 0.7;
  pointer-events: none;
}

@media(max-width: 990px){
  .center .slick-center .project{
    transform: scale(1);
  }

  .center .project{
    opacity: 1;
    pointer-events: all;
  }
}


/* scroll bar style  */


/* Track */
/* ::-webkit-scrollbar-track {
  background: #f1f1f1;
} */

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 50px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

@keyframes header {
  0%{
    transform: translateY(-30px);
    opacity: 0;
  } 
  100%{
    transform: translateY(0);
      opacity: 1;
  }
}