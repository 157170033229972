@font-face{
    font-family: 'Lab Sans Pro Thin';
    font-weight: bold;
    src: local('Lab Sans Pro Thin'), url('../../lab-sans-pro-cufonfonts-webfont/Lab\ Sans\ Pro-Thin.woff') format('woff');
  }

.navbar {
    /* position: fixed;
    top: 0;
    left: 0; */
    width: 100%;
    height:100vh;
    /* background: linear-gradient(107.29deg, #050A30 15.64%, #4699AB 180.36%); */
    z-index: 999;
    height: 50px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding:20px 7% 0 7%;
    /* gap:25vw; */
  }

  .menu{
    font-family: 'Iceland';
font-style: normal;
font-weight: 400;
font-size: 20px !important;
line-height: 19px;

color: #FFFFFF;
  }
  
  .navbar-toggle {
    display: block;
    background: none;
    border: none;
    cursor: pointer;
    /* padding: 10px; */
    margin :0 !important;
    outline: none;
  }
  
  .navbar-toggle-icon {
    display: block;
    width: 20px;
    height: 2px;
    background-color: #333;
    position: relative;
    transition: transform 0.3s ease;
  }
  
  .navbar-toggle-icon:before,
  .navbar-toggle-icon:after {
    content: '';
    position: absolute;
    width: 20px;
    height: 2px;
    background-color: #333;
    transition: transform 0.3s ease;
  }
  
  .navbar-toggle-icon:before {
    top: -6px;
  }
  
  .navbar-toggle-icon:after {
    top: 6px;
  }
  
  .navbar-components {
    position: fixed;
    top: -111%;
    left: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    justify-content: center;
    background: #102245 !important;
    padding: 0px 44% 0 27%;
        transition: top 0.9s ease-in-out;
    height: 100vh;
    text-align: left;
    gap:1.3vh;
  }
  
  .navbar-components.open {
    top: 0;
  }
  
  .close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    cursor: pointer;
    padding: 5px;
    outline: none;
  }
  
  .close-button-icon {
    display: block;
    width: 20px;
    height: 2px;
    background-color: #333;
    position: relative;
    transform: rotate(45deg);
    transition: transform 0.3s ease;
  }
  
  .close-button-icon:before,
  .close-button-icon:after {
    content: '';
    position: absolute;
    width: 20px;
    height: 2px;
    background-color: #333;
    transition: transform 0.3s ease;
  }
  
  .close-button-icon:before {
    top: -6px;
    transform: rotate(-90deg);
  }
  
  .close-button-icon:after {
    top: 6px;
    transform: rotate(90deg);
  }
  
  .navbar-component {
    margin-bottom: 10px;
    text-decoration: none;
    font-style: normal;
    font-family: 'Lato';
    font-weight: 300;
font-size: 40px;
line-height: 45px;
text-align: center;

color: #FFFFFF;
  }

  .imgmob{
    width: 122px;
height: 25px;
  }