@font-face{
    font-family: 'Lab Sans Pro';
    font-weight: bold;
    src: local('Lab Sans Pro'), url('../../lab-sans-pro-cufonfonts-webfont/Lab\ Sans\ Pro-Thin.woff') format('woff');
}
@import url(//db.onlinewebfonts.com/c/f5f6ff7f3ec1bc2c964b9a8d5122840b?family=Lab+Sans+Pro+Light);
@import url('https://fonts.googleapis.com/css2?family=Iceland&family=Nunito:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;0,1000;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900;1,1000&display=swap');

.contact1{
    min-height: 136vh;
    height: auto;
    width: 100vw;
    max-width:1920px;
        background: linear-gradient(183.75deg, #050A30 34.93%, #4699AB 373.46%);
        display: flex;
        justify-content: center;
        align-items: center;
border: 2px solid ;

border: 1px solid rgb(61, 61, 61);
border-left: 0px;
border-right: 0px;
border-top: none !important;
border-radius: 0px 0px 143px 143px;



}
.contactus{
    color: #FFF;
text-align: center;
font-size: 5vw;
font-family: Montserrat;
font-style: normal;
font-weight: 400;
line-height: normal;
margin-left: 40%;
width: 30vw;
}

@media only screen and (max-width:700px){
    .contactus {
        font-size: 10vw;
        margin-left: -3%;
        width: 100vw;
    }
}

@media only screen and (max-width:1250px){
    .contact1{
        margin-top: -14%;
    }
}
@media only screen and (max-width:1050px){
    .contact1{
        margin-top: -18%;
        min-height: 126vh;

    }
}
@media only screen and (max-width:850px){
    .contact1{
        margin-top: -20%;
        min-height: 115vh;

    }
}
@media only screen and (max-width:470px){
    .contact1{
        margin-top: -24%;
        min-height: 105vh;

    }
}
@media only screen and (max-width:380px){
    .contact1{
        margin-top: -30%;
        min-height: 92vh;

    }
}
@media only screen and (min-width:1800px){
    .contact1{
        min-height: 110vh;
        margin-top: -8.5%;
    }
    .contactinfos{
        min-height: 90vh !important;

    }
}

.navy{
    height: 100px;
}
.contactimg{
    width: 40%;
    height: 40%;
    margin-top: -6%;
    position: relative;
    left:7%;
}

.contactimgmob{
    display: none;
}

.contactinfo1{
    display: flex;
    align-items: center;
    justify-content: center;
    width:100%;
    /* position: absolute; */
    /* top: 31vh; */
    /* font-family: 'Lab Sans Pro Thin';
    font-weight: bold; */
    font-style: normal;
    font-size: 90px;
    line-height: 104px;
    text-align: center;
    color: white;
    font-family: 'Montserrat',sans-serif !important;
    font-weight: 300;
    letter-spacing: -0.11rem;
    
}

.contactform{
    min-height: 110vh;
    height: auto;
    width:100vw;
    max-width: 1920px;
    display: flex;
    justify-content: center;
    align-items: center;
    /* background: linear-gradient(107.29deg, rgba(0, 26, 30, 0.8) 15.64%, rgba(70, 153, 171, 0.8) 180.36%); */
      
}


.contactform img{
    width:22vw;
    max-width:429px;
    position: relative;
    left: 7vw;
}


.contactinfos{
    min-height:95vh;
    height: auto;
    width:100vw;
    max-width:1920px;
    display:flex ;
    /* justify-content: center; */
    align-items: center;
    text-align: center;
    flex-direction: column;
    /* background: linear-gradient(107.29deg, rgba(0, 26, 30, 0.65) 15.64%, rgba(70, 153, 171, 0.65) 180.36%); */
    
background: linear-gradient(1.97deg, #070E21 10.2%, #183354 99.16%);
}

.writing{
    font-family: 'lato',sans-serif;
    font-weight: 500;
    font-size: 1.5vw;
}

.contactsub{

    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    width:100%;
    gap:88px;
}
.contactsub div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 8px;
    font-style: normal;
    font-family: 'Montserrat',sans-serif;
    font-weight: 600;
    letter-spacing: -0.03em;
    font-size: 29px;
    line-height: 27px;
    text-align: center;
    letter-spacing: 0.02em;
    color: #ffffff;
}

.contactinfos h2{
    font-family: 'Montserrat',sans-serif;
    font-weight: 400;
    letter-spacing: -0.03em;
font-style: normal;
font-size: 107.692px;
line-height: 104px;
text-align: center;

color: #ffffff;
}

.boxes{
    background: linear-gradient(116.8deg, rgba(255, 255, 255, 0.5) -10.33%, rgba(125, 240, 255, 0.5) 56.77%);
mix-blend-mode: normal;
box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
border-radius: 12px;
width: 39vw;
    height: 12vh;
}

@media only screen and (max-width: 1140px){
    .contactinfo1{
        font-size: 60px;
    }
}

@media only screen and (max-width:1025px){
    .contactsub div{
        font-size: 40px;
    }
    .contactinfos h2{
        font-size: 90px;
    }
}

@media only screen and (max-width:900px){
   
}

@media only screen and (max-width:869px){
    .contactsub div{
        font-size: 30px;
        width:90vw;
    }
    .contactinfos h2{
        font-size: 70px;
    }
}



@media only screen and (max-width:700px){
    .contactimg{
       display: none;
    }
    .contactform{
        flex-direction: column;
    }
    .navy{
        height: 90px;
    }
    .contactimgmob{
        display: inline-block;
        width: 67vw;
        height:59vw;
    }
    .contactinfo1{
        font-size: 36px;
        line-height: 47px;
    }
   .contact1{
    flex-direction: column;
    border-radius: 0px 0px 50px 50px;
   }
    .contactsub div{
        background: rgba(70, 153, 171, 0.5);
        border-radius: 5px;
        line-height: 34px;
        font-size: 24px;
    }
    .contactsub{
        gap:40px;
    }
    .contactinfos h2{
        font-size: 48px;
    }
    .contactform img {
        width: 60vw;
        position: relative;
        left: 0vw;
        padding: 25% 0;
    }
    .writing{
       
        font-size: 4vw;
    }
    .contactus {
        font-size: 10vw;
        margin-left: -3%;
        margin-top: 43%;
        width: 100vw;
    }
    
}

@media only screen and (max-width:540px){
    .contactsub div{
        line-height: 26px;
    font-size: 18px;
    }

    .contactinfos h2{
        font-size: 40px;
    }
}
@media only screen and (max-width:410px){
    .contactsub div {
        line-height: 24px;
        font-size: 20px;
    }

    .contactinfos h2{
        font-size: 32px;
    }
}

@media only screen and (min-width: 2200px){
.contactinfos {
    min-height: 125vh !important;
}
}